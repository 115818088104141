import React from "react"
import styled, { css } from "styled-components"

interface Props {
  children: React.ReactNode
  href: string
  mode?: "dark" | "light"
}

const ExternalLink = ({ children, href, mode = "light" }: Props) => (
  <AnchorExternalLink
    href={href}
    isDark={mode === "light"}
    rel="noopener noreferrer"
    target="_blank"
  >
    {children}
  </AnchorExternalLink>
)

const AnchorExternalLink = styled.a<{ isDark: boolean }>`
  text-decoration: none;

  ${({ isDark }) =>
    isDark
      ? css`
        `
      : css`
          &:link {
            color: #61dafb;
          }
          &:visited {
            color: #d499ff;
          }
        `}

  &:hover {
    text-decoration: underline;
  }
`

export default ExternalLink
