import React from "react"
import styled from "styled-components"

import ExternalLink from "./ExternalLink"

interface Props {
  href: string
  imgSrc: string
  name: string
  position: string
  preposition?: string
}

const Job = ({ href, imgSrc, name, position, preposition = "as" }: Props) => (
  <DivJob>
    <ExternalLink href={href}>
      <img alt={name} className="Job--logo" src={imgSrc} title={name} />
    </ExternalLink>
    <div className="Job--position">
      <span className="Job--position-preposition">{preposition}</span>{" "}
      {position}
    </div>
  </DivJob>
)

const DivJob = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(48px + 24vmin);

  .Job--logo {
    width: calc(24px + 16vmin);
  }

  .Job--position {
    color: #8e929a;
    font-size: calc(2vmin);
    margin-top: 8px;
    text-align: center;
  }

  .Job--position-preposition {
    color: #c1c5cd;
  }
`

export default Job
