import React from "react"
import styled from "styled-components"

import ExternalLink from "./components/ExternalLink"
import Job from "./components/Job"

const App: React.FC = () => (
  <DivApp>
    <header className="App--header">
      <div className="App--name">Joshua Wu</div>
      <div className="App--contact">
        <ExternalLink href="mailto:contact@joshuawu.com" mode="dark">
          contact@joshuawu.com
        </ExternalLink>
        <ExternalLink
          href="https://www.linkedin.com/in/joshua-wu-b083a415/"
          mode="dark"
        >
          <img alt="LinkedIn" className="App--linkedin" src="linkedin.png" />
        </ExternalLink>
      </div>
    </header>
    <main className="App--main">
      <section className="App--section">
        <header className="App--section-heading">Background</header>
        <div className="App--career">
          <Job
            href="https://www.berkeley.edu/"
            imgSrc="uc-berkeley.png"
            name="UC Berkeley"
            position="electrical engineering and computer sciences"
            preposition="in"
          />
          <Job
            href="https://www.facebook.com/"
            imgSrc="facebook.png"
            name="Facebook"
            position="partnerships tech lead"
          />
          <Job
            href="https://opensea.io/"
            imgSrc="opensea.png"
            name="OpenSea"
            position="founding engineer"
          />
        </div>
        <div className="App--old-website">
          (more info on{" "}
          <ExternalLink href="http://old.joshuawu.com">
            the old website
          </ExternalLink>
          )
        </div>
      </section>
      <section className="App--section">
        <header className="App--section-heading">Fun Stuff</header>
        <ul className="App--fun-stuff">
          <li className="App--fun-stuff-item">
            <span className="App--fun-stuff-caption">
              For word game enthusiasts:
            </span>
            <ExternalLink href="http://www.runeval.com/">unravel</ExternalLink>
          </li>
          <li className="App--fun-stuff-item">
            <span className="App--fun-stuff-caption">For karaoke lovers:</span>
            <ExternalLink href="http://joysound.joshuawu.com/">
              Joysound song search tool
            </ExternalLink>
          </li>
          <li className="App--fun-stuff-item">
            <span className="App--fun-stuff-caption">For go players:</span>
            <ExternalLink href="http://www.josekifarm.com/">
              joseki farm
            </ExternalLink>
          </li>
        </ul>
      </section>
    </main>
  </DivApp>
)

const DivApp = styled.div`
  .App--header {
    align-items: center;
    background-color: #282c34;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 20vh;
    padding: 24px 0;
  }

  .App--name {
    font-size: calc(16px + 2vmin);
    font-variant: small-caps;
  }

  .App--contact {
    align-items: center;
    display: flex;
    font-size: calc(12px + 1vmin);
  }

  .App--linkedin {
    display: flex;
    height: calc(24px + 0.5vmin);
    margin-left: 12px;
  }

  .App--main {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 24px 0;
  }

  .App--section {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 1024px;
    padding-bottom: 64px;
    width: 100%;
  }

  .App--section-heading {
    font-size: calc(16px + 2vmin);
    font-variant: small-caps;
    margin-bottom: 24px;
  }

  .App--career {
    align-items: flex-start;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  .App--old-website {
    color: #8e929a;
    font-size: calc(4px + 1vmin);
    margin-top: calc(8px + 2vmin);
  }

  .App--fun-stuff {
    font-size: calc(4px + 1.5vmin);
    list-style: none;
    margin: auto;
    padding: 0 24px;
  }

  .App--fun-stuff-item {
    margin-bottom: calc(4px + 1.5vmin);
  }

  .App--fun-stuff-caption {
    color: #8e929a;
    margin-right: 8px;
  }
`

export default App
